import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OrganizationAudienceService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationAudiences(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/audiences`, params);
  }

  syncOrganizationAudiences(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/audience-sync`, params);
  }

}
