import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class NotificationService {

  constructor(
    private apiService: ApiService
  ) { }

  getNotifications(organizationId: string, params: any = {}) {
    return this.apiService
      .get(`organizations/${organizationId}/notifications`, params);
  }

  readNotification(organizationId: string, notificationId: string, params: any = {}) {
    return this.apiService
      .put(`organizations/${organizationId}/notifications/${notificationId}/read`, params);
  }

  unreadNotification(organizationId: string, notificationId: string, params: any = {}) {
    return this.apiService
      .put(`organizations/${organizationId}/notifications/${notificationId}/unread`, params);
  }

  notify(params: any = {}) {
    return this.apiService
      .post(`users/notify`, params);
  }

}
