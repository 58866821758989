<div class="rtz-registration-wrap">
  <div class="rt-loading on" [class.on]="loadingGlobal">
    <div class="rt-spinner"></div>
  </div>

  <div class="rt-row">
    <div class="rt-column desk-6">

    </div>
    <div class="rt-column desk-6">
      <div class="rt-column-content">
        <div class="rt-auth-panel">  
          @if (!emailLogin) {
            <div class="rtz-reg-intro">
              <img src="assets/riterz-icon.svg" alt="Riterz" />
              <h1>Login</h1>
            </div>
            <nav class="rtz-registration-options">
              <ul>
                <li>
                  <a
                    href="{{ apiRoot }}../auth/socialite/google/redirect?return_url={{ returnUrl }}"
                    class="ro-google"
                    title="Continue with Google"
                    >Continue with Google</a
                  >
                </li>
                <li>
                  <a
                    href="{{ apiRoot }}../auth/socialite/facebook/redirect?return_url={{ returnUrl }}"
                    class="ro-fb"
                    title="Continue with Facebook"
                    >Continue with Facebook</a
                  >
                </li>
                <li>
                  <a
                    href="{{ apiRoot }}../auth/socialite/azure/redirect?return_url={{ returnUrl }}"
                    class="ro-ms"
                    title="Continue with Microsoft"
                    >Continue with Microsoft</a
                  >
                </li>
                <li>
                  <a
                    (click)="emailLogin = true"
                    class="ro-email rt-clickable"
                    title="Continue with Email"
                    >Continue with Email</a
                  >
                </li>
              </ul>
            </nav>  

            <div class="rt-column desk-12">
              <div class="rt-column-content">
                <div class="rt-switch-auth">
                  Don't have an account? <a (click)="toRegistration()">Register</a>
                </div>
              </div>
            </div>

          } @else {
            <div class="rtz-reg-intro">
              <p-button
                class="rt-auth-back"
                (click)="emailLogin = false"
                icon="pi pi-arrow-left"
                size="small"
                severity="secondary"
                [text]="true"
                [rounded]="true"
              ></p-button>
              <img src="assets/riterz-icon.svg" alt="Riterz" />
              <h1>Unlock full access</h1>
            </div>
            <div class="rtz-reg-login">
              <form class="rt-row gap-16 propagate" [formGroup]="loginForm">
                <div class="rt-column desk-12 phab-12">
                  <div class="rt-column-content">
                    <label for="email">Email</label>
                    <input
                      [style]="{ width: '100%' }"
                      id="email"
                      type="email"
                      formControlName="email"
                      (keyup.enter)="login()"
                      pInputText
                    />
                    @if (
                      loginForm.controls['email'].touched &&
                      loginForm.controls['email'].hasError('required')
                    ) {
                      <div class="error-message">Email is required</div>
                    }
                    @if (
                      loginForm.controls['email'].touched &&
                      loginForm.controls['email'].hasError('pattern')
                    ) {
                      <div class="error-message">Email is not in correct format</div>
                    }
                  </div>
                </div>
                <div class="rt-column desk-12 phab-12">
                  <div class="rt-column-content">
                    <label for="password">Password</label>
                    <input
                      [style]="{ width: '100%' }"
                      id="password"
                      type="password"
                      formControlName="password"
                      (keyup.enter)="login()"
                      pInputText
                    />
                    @if (
                      loginForm.controls['password'].touched &&
                      loginForm.controls['password'].hasError('required')
                    ) {
                      <div class="error-message">Password is required</div>
                    }
                  </div>
                </div>
      
                <div class="rt-column desk-12">
                  <div class="rt-column-content">
                      <span>Forgot password? <a class="rt-clickable" (click)="requestLoginCode()">Request login link</a></span>
                  </div>
                </div>
              </form>
      
              <div class="rt-row">
                <div class="rt-column desk-12">
                  <div class="rt-column-content">
                    <p-button
                      [style]="{ width: '100%' }"
                      [loading]="loggingIn"
                      (click)="login()"
                      [disabled]="loginForm.invalid"
                      severity="primary"
                      [rounded]="true"
                      label="Log in"
                    />
                  </div>
                </div>

                <div class="rt-column desk-12">
                  <div class="rt-column-content">
                    <div class="rt-switch-auth">
                      Don't have an account? <a (click)="toRegistration()">Register</a>
                    </div>
                  </div>
                </div>
              </div>

              <p class="reg-footnote">
                This site is protected by reCAPTCHA and the Google’s
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  title="Privacy Policy"
                  >Privacy Policy</a
                >
                and
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  title="Terms of Service"
                  >Terms of Service</a
                >
                apply.
              </p>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

</div>
